import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const IDContext = createContext(null)

export const IDProvider = ({ children }) => {
  const location = useLocation()
  const [id, setId] = useState(null)

  useEffect(() => {
    const idFromURL = new URLSearchParams(location.search).get('id') || location.pathname.split('/')[2]
    setId(idFromURL)
  }, [location])

  return <IDContext.Provider value={id}>{children}</IDContext.Provider>
}

export const useID = () => useContext(IDContext)
