import * as THREE from 'three'
import { useRef, useLayoutEffect, useState, useMemo } from 'react'
import { Canvas, useThree, useLoader } from '@react-three/fiber'
import { Center, AccumulativeShadows, RandomizedLight, OrbitControls, Environment, useGLTF } from '@react-three/drei'
import React, { useEffect, Component } from 'react'
import AWS from 'aws-sdk'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import './styles.css' // Make sure to link your CSS file
import { useTexture } from '@react-three/drei'
import { extend } from '@react-three/fiber'
import { TextureLoader } from 'three'
import TWEEN from '@tweenjs/tween.js'
import { useFrame } from '@react-three/fiber'
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'
import ReactDOM from 'react-dom'
import AppRouter from './AppRouter'
import { useID } from './IDContext' // Adjust the path as necessary
import { IDProvider } from './IDContext'
import createApp from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions'
import { createRoot } from 'react-dom/client'
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
)

// Start OAuth flow by calling the Netlify function
const startAuth = async (shop) => {
  window.location.href = `/.netlify/functions/auth?shop=${shop}`
}

function AnimationUpdater() {
  useFrame(() => TWEEN.update())
  return null // This component does not render anything
}

function setEnvironmentMap(scene) {
  const loader = new RGBELoader()
  loader.load('https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/abandoned_greenhouse_1k.hdr', (texture) => {
    texture.mapping = THREE.EquirectangularReflectionMapping
    scene.environment = texture
  })
}

function GroundPlane() {
  const texture = useLoader(TextureLoader, 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/240415_Shadowbaked.png?v=1713229807')
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping
  texture.repeat.set(1, 1)
  texture.anisotropy = 16 // Enhance texture quality

  // Darken the texture by applying a darker color multiplier
  const material = new THREE.MeshStandardMaterial({
    map: texture,
    transparent: true
  })

  return (
    <mesh rotation={[-Math.PI / 2, 0, -0.02]} position={[-0.88, -0.3, 1.1]} scale={[0.4, 0.4, 0.4]}>
      <planeGeometry args={[500, 500]} />
      <primitive object={material} attach="material" />
    </mesh>
  )
}

function LoadingIndicator() {
  const sprocketColor = '#EA8B6A' // Define the exact color

  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: 100
      }}>
      {/* Main sprocket */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        <img
          src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/sprocket.svg?v=1717219866"
          alt="Loading"
          style={{
            width: '150px', // Three times original size
            height: '150px',
            animation: 'spin 2s linear infinite',
            filter: `invert(53%) sepia(78%) saturate(3000%) hue-rotate(0deg) brightness(93%) contrast(90%)` // Matches #EA8B6A
          }}
        />
      </div>

      {/* Text below the main sprocket */}
      <p
        style={{
          position: 'absolute',
          top: 'calc(50% + 80px)',
          left: '50%',
          transform: 'translateX(-50%)',
          color: '#FFFFFF', // White text
          fontSize: '16px'
        }}>
        Loading...
      </p>

      {/* Additional sprockets */}
      {[1, 2, 3].map((_, index) => (
        <img
          key={index}
          src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/sprocket.svg?v=1717219866"
          alt="Loading"
          style={{
            position: 'absolute',
            top: `${10 + index * 5}%`, // Scatter vertically
            left: `${10 + index * 25}%`, // Scatter horizontally
            width: `${50 + index * 20}px`, // Varying sizes
            height: `${50 + index * 20}px`,
            animation: `spin 2s linear infinite ${index * 0.5}s`, // Delay to offset spin
            filter: `invert(53%) sepia(78%) saturate(3000%) hue-rotate(0deg) brightness(93%) contrast(90%)` // Matches #EA8B6A
          }}
        />
      ))}

      <style>
        {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  )
}

function LightMarker({ position }) {
  const mesh = useRef()
  const lightMaterial = useMemo(() => new THREE.MeshBasicMaterial({ color: 0xffff00 }), [])
  return (
    <mesh ref={mesh} position={position} material={lightMaterial}>
      <sphereGeometry args={[0.1, 16, 16]} />
    </mesh>
  )
}

function Suzi({ color, screenType, screenColor, fabricColor, bottomScreenType, microphoneType, activeSelection, isThumbnail, ...props }) {
  const { scene } = useGLTF('https://cdn.shopify.com/3d/models/bd60ece5eec8c1bc/241101_customizer_smaller.glb')

  useLayoutEffect(() => {
    scene.traverse((obj) => {
      if (obj.isMesh) {
        obj.receiveShadow = obj.castShadow = true
        obj.visible = false // Initially hide all meshes.

        if (obj.name === 'body') {
          obj.material.color.set(color)
          obj.material.roughness = 0.5 // Adds some slight roughness
          obj.material.metalness = 0.7
          obj.visible = true
        }
        if (obj.name === 'saati_top') {
          obj.material.color.set(fabricColor)
          obj.material.transparent = true // Make material transparent
          let opacity = fabricColor === '#FFFFFF' ? 0.55 : 0.55 // Set opacity based on fabricColor, though this conditional seems unnecessary
          obj.material.opacity = isThumbnail ? 1 : opacity // Adjust opacity for thumbnail
          obj.visible = true
        }
        if (obj.name === 'saati_bottom') {
          obj.material.color.set(fabricColor)
          obj.material.transparent = false // Make material opaque
          obj.visible = true
        }

        if (obj.name === 'saati_bottomback') {
          obj.material.color.set(fabricColor)
          obj.material.transparent = false // Make material opaque
          obj.visible = true
        }
        const isScreenTopVisible = obj.name === `${screenType}_top`
        const isScreenBottomVisibleBack = obj.name === `${screenType}_bottomback`

        if (isScreenTopVisible || isScreenBottomVisibleBack) {
          obj.material.color.set(screenColor)
          obj.material.needsUpdate = true
          obj.material.roughness = 0.5 // Adds some slight roughness
          obj.material.metalness = 0.7
          obj.visible = true
        }

        const isBottomScreenVisible = bottomScreenType === 'Add Pattern Screen' && obj.name === `${screenType}_bottom`
        if (isBottomScreenVisible) {
          obj.material.color.set(screenColor)
          obj.material.roughness = 0.5 // Adds some slight roughness
          obj.material.metalness = 0.2
          obj.visible = true
        }

        if ((bottomScreenType === 'Logo' && obj.name === 'logo') || (bottomScreenType === 'Custom Logo' && obj.name === 'custom_logo')) {
          obj.material.color.set(screenColor)
          obj.material.roughness = 0.5 // Adds some slight roughness
          obj.material.metalness = 0.2
          obj.visible = true
        }

        if ((microphoneType === 'Condenser' && obj.name.includes('capsule')) || (microphoneType === 'Ribbon' && obj.name.includes('ribbon'))) {
          obj.visible = true
        }
      }
    })
  }, [color, screenType, screenColor, fabricColor, bottomScreenType, microphoneType, isThumbnail])

  useEffect(() => {
    scene.traverse((obj) => {
      if (obj.isMesh) {
        // Ensure 'body' and 'saati_bottom' are always visible
        if (obj.name === 'body' || obj.name === 'saati_bottom') {
          obj.visible = true
        }

        // Fade-out or fade-in for top screens only when activeSelection changes
        if (obj.name === `${screenType}_top` || obj.name === 'saati_top') {
          const targetOpacity = activeSelection === '6. Microphone Type' ? 0 : obj.name === 'saati_top' ? (isThumbnail ? 1 : 0.55) : isThumbnail ? 1 : 1

          // Clone the material if not already cloned
          if (!obj.material._isCloned) {
            obj.material = obj.material.clone()
            obj.material._isCloned = true
          }

          // Only apply opacity change when activeSelection changes
          if (activeSelection !== '6. Microphone Type' || targetOpacity === 0) {
            obj.material.transparent = true

            // Only make visible if targetOpacity > 0 to prevent unintended reappearance
            if (targetOpacity > 0) {
              obj.visible = true
            }

            new TWEEN.Tween(obj.material)
              .to({ opacity: targetOpacity }, 500)
              .easing(TWEEN.Easing.Quadratic.Out)
              .onUpdate(() => {
                obj.material.needsUpdate = true
              })
              .onComplete(() => {
                // Hide the object only if opacity is fully faded out
                if (targetOpacity === 0) {
                  obj.visible = false
                }
              })
              .start()
          }
        }

        // Control visibility of 'capsule' and 'ribbon' based on microphoneType only, without affecting top screen
        if ((microphoneType === 'Condenser' && obj.name === 'capsule') || (microphoneType === 'Ribbon' && obj.name === 'ribbon')) {
          obj.visible = true
        } else if (obj.name === 'capsule' || obj.name === 'ribbon') {
          obj.visible = false
        }
      }
    })
  }, [activeSelection, microphoneType, screenType, screenColor, scene, isThumbnail])

  useFrame(() => {
    TWEEN.update() // necessary for tween animations to update on each frame
  })

  return <primitive object={scene} {...props} />
}

function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

function createThumbnailCamera(originalCamera) {
  const aspect = 1 // Square aspect ratio
  const thumbnailCamera = originalCamera.clone()
  thumbnailCamera.aspect = aspect
  thumbnailCamera.updateProjectionMatrix()
  return thumbnailCamera
}

function setActiveDot(dotClassName, cameraFunction) {
  // Remove "active" class from all dots
  document.querySelectorAll('.control-dots div').forEach((dot) => dot.classList.remove('active'))

  // Add "active" class to the selected dot
  document.querySelector(`.${dotClassName}`).classList.add('active')

  // Call the camera function passed in the arguments
  if (cameraFunction) {
    cameraFunction()
  }
}

const urlParams = new URLSearchParams(window.location.search)
const code = urlParams.get('code')
const host = urlParams.get('host') // 'host' should be present in your app URL

const response = await fetch(`https://ohma-world.myshopify.com/admin/oauth/access_token`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({
    client_id: process.env.SHOPIFY_API_KEY, // Client ID from Shopify Partners
    client_secret: process.env.SHOPIFY_API_SECRET, // Client Secret from Shopify Partners
    code // Code from the authorization redirect
  })
})

const data = await response.json()
const accessToken = data.access_token
console.log('Shopify Access Token:', accessToken)

if (host) {
  const app = createApp({
    apiKey: process.env.SHOPIFY_API_KEY,
    host
  })

  // Example: Redirecting to another page in the Shopify Admin using App Bridge
  const redirect = Redirect.create(app)
  redirect.dispatch(Redirect.Action.ADMIN_PATH, '/settings')
} else {
  console.error('Host parameter is missing from the URL.')
}

export default function App() {
  const sceneRef = useRef()
  const cameraRef = useRef()
  const orbitControlsRef = useRef()

  const [color, setColor] = useState('#4fb9af')
  const [screenType, setScreenType] = useState('motif')
  const [screenColor, setScreenColor] = useState('#FFFFFF')
  const [fabricColor, setFabricColor] = useState('#FFFFFF')
  const [bottomScreenType, setBottomScreenType] = useState('Logo')
  const [microphoneType, setMicrophoneType] = useState('Condenser')
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [fetchedData, setFetchedData] = useState(null)

  const fetchDataWithFileId = async (fileid) => {
    try {
      const response = await fetch(`https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/variables/fileid/${fileid}`)
      if (!response.ok) throw new Error(`Failed to fetch data for fileid: ${fileid}`)
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error fetching data with fileid:', error)
      return null
    }
  }

  useEffect(() => {
    const fetchDataWithIdFromURL = async () => {
      const url = new URL(window.location.href)
      const fileid = url.pathname.split('/').pop() // Extract `fileid` from the URL

      if (fileid) {
        console.log('Detected fileid:', fileid) // Log for debugging

        try {
          const data = await fetchDataWithFileId(fileid)

          if (data) {
            console.log('Fetched data:', data) // Log the fetched data

            // Break out fetched values into variables
            const fetchedColor = data.color || ''
            const fetchedScreenType = data.screen_type || ''
            const fetchedScreenColor = data.screen_color || ''
            const fetchedFabricColor = data.fabric_color || ''
            const fetchedBottomScreenType = data.bottom_screen_type || ''
            const fetchedMicrophoneType = data.microphone_type || ''

            // Update state with fetched values
            setColor(fetchedColor)
            setScreenType(fetchedScreenType)
            setScreenColor(fetchedScreenColor)
            setFabricColor(fetchedFabricColor)
            setBottomScreenType(fetchedBottomScreenType)
            setMicrophoneType(fetchedMicrophoneType)
          } else {
            console.error('No data found for fileid:', fileid)
          }
        } catch (error) {
          console.error('Error fetching data for fileid:', fileid, error)
        }
      } else {
        console.log('No fileid provided in URL.')
      }
    }

    fetchDataWithIdFromURL()
  }, [])

  const lightPosition = [5, 5, 10]
  const canvasRef = useRef() // Define canvasRef here
  const [sceneReady, setSceneReady] = useState(false) // State to track scene readiness
  const modelRef = useRef()
  const [isAnimating, setIsAnimating] = useState(false)

  const componentRef = useRef(null)

  const [shopName, setShopName] = useState('your-store.myshopify.com')

  // "Body Color" draggable state and references
  const colorScrollRef = useRef(null) // Ref for color options container
  const [isColorDragging, setIsColorDragging] = useState(false) // Color dragging state
  const [startColorX, setStartColorX] = useState(0) // Starting X for color drag
  const [scrollLeftColor, setScrollLeftColor] = useState(0) // Initial scroll position for color drag

  // "Screen Color" draggable state and references
  const screenColorScrollRef = useRef(null) // Ref for screen color options container
  const [isScreenColorDragging, setIsScreenColorDragging] = useState(false) // Screen color dragging state
  const [startScreenColorX, setStartScreenColorX] = useState(0) // Starting X for screen color drag
  const [scrollLeftScreenColor, setScrollLeftScreenColor] = useState(0) // Initial scroll position for screen color

  // Event handlers for "Body Color" dragging functionality
  const handleColorMouseDown = (e) => {
    setIsColorDragging(true)
    setStartColorX(e.pageX || e.touches[0].pageX)
    setScrollLeftColor(colorScrollRef.current.scrollLeft)
  }

  const handleColorMouseMove = (e) => {
    if (!isColorDragging) return
    const x = e.pageX || e.touches[0].pageX
    const walk = (x - startColorX) * 1.5 // Adjust scroll sensitivity here if needed
    colorScrollRef.current.scrollLeft = scrollLeftColor - walk
  }

  const handleColorMouseUpOrLeave = () => setIsColorDragging(false) // Reset dragging state

  // Event handlers for "Screen Color" dragging functionality
  const handleScreenColorMouseDown = (e) => {
    setIsScreenColorDragging(true)
    setStartScreenColorX(e.pageX || e.touches[0].pageX)
    setScrollLeftScreenColor(screenColorScrollRef.current.scrollLeft)
  }

  const handleScreenColorMouseMove = (e) => {
    if (!isScreenColorDragging) return
    const x = e.pageX || e.touches[0].pageX
    const walk = (x - startScreenColorX) * 1.5 // Adjust scroll sensitivity here if needed
    screenColorScrollRef.current.scrollLeft = scrollLeftScreenColor - walk
  }

  const handleScreenColorMouseUpOrLeave = () => setIsScreenColorDragging(false) // Reset dragging state

  const forceUpdateComponent = () => {
    if (componentRef.current) {
      componentRef.current.forceUpdate() // Call forceUpdate method on the component instance
    }
  }

  async function sendDataToServer() {
    const data = {
      receivedColor,
      receivedColorName,
      receivedScreenType,
      receivedScreenTypeName,
      receivedScreenColor,
      receivedScreenColorName,
      receivedFabricColor,
      receivedFabricColorName,
      receivedBottomScreenType,
      receivedMicrophoneType,
      receivedImageUrl
    }

    try {
      const response = await fetch('https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/receive-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      console.log('Data sent successfully')
    } catch (error) {
      console.error('Failed to send data:', error.message)
    }
  }

  useEffect(() => {
    const preventArrowScroll = (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        event.preventDefault() // Prevent the default arrow key behavior
      }
    }

    window.addEventListener('keydown', preventArrowScroll)
    return () => window.removeEventListener('keydown', preventArrowScroll)
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = '' // Reset on cleanup
    }
  }, [])

  const swayCamera = () => {
    if (!cameraRef.current || isAnimating) {
      console.log('Camera not ready or animation already in progress')
      return // Prevent animation from starting again if it's already running
    }

    setIsAnimating(true)
    const initialRotationZ = cameraRef.current.rotation.z
    const swayAmount = Math.PI / 6 // This represents 30 degrees

    let cycles = 2 // Total back and forth motions

    const animateSway = (direction) => {
      if (cycles === 0) {
        new TWEEN.Tween(cameraRef.current.rotation)
          .to({ z: initialRotationZ }, 75)
          .easing(TWEEN.Easing.Quadratic.Out)
          .onComplete(() => setIsAnimating(false)) // Reset animation flag when done
          .start()
        return
      }

      const targetZ = direction ? initialRotationZ + swayAmount : initialRotationZ - swayAmount
      const tween = new TWEEN.Tween(cameraRef.current.rotation)
        .to({ z: targetZ }, 75)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onComplete(() => {
          cycles--
          animateSway(!direction) // Switch direction each call
        })

      tween.start()
    }

    animateSway(true) // Start the animation with the first sway to the right
  }

  const screenTypeOptions = {
    motif: { label: 'Motif', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Icon_Round_Grey_Motif.png?v=1699696038' },
    stripes: { label: 'Stripes', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Icon_Round_Grey_Stripes.png?v=1699696038' },
    windows: { label: 'Windows', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Icon_Round_Grey_Windows.png?v=1699696038' },
    scales: { label: 'Scales', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Icon_Round_Grey_Scales.png?v=1699696038' },
    holes: { label: 'Holes', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Icon_Round_Grey_Holes.png?v=1699696038' }
    // Add other screen types as necessary
  }

  const bottomScreenTypeOptions = {
    Logo: {
      label: 'Logo',
      iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Icon_Round_Grey_Motif.png?v=1699696038' // Placeholder URL
    },
    'Add Pattern Screen': {
      label: 'Add Pattern Screen',
      iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Icon_Round_Grey_Motif.png?v=1699696038' // Placeholder URL
    },
    'Custom Logo': {
      label: 'Custom Logo',
      iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Icon_Round_Grey_Motif.png?v=1699696038' // Placeholder URL
    }
  }

  const randomizeSelection = (options, weights = {}) => {
    let weightedOptions = []

    // Build an array containing each option repeated according to its weight
    Object.entries(options).forEach(([key, value]) => {
      const repeats = weights[key] || 1 // Use the weight if specified, or default to 1
      for (let i = 0; i < repeats; i++) {
        weightedOptions.push(key)
      }
    })

    // Select a random option from the weighted array
    const randomIndex = Math.floor(Math.random() * weightedOptions.length)
    return options[weightedOptions[randomIndex]]
  }

  const weights = {
    motif: 40, // Motif should come up 35% of the time
    stripes: 10,
    windows: 10,
    scales: 10,
    holes: 10
  }

  const newScreenType = randomizeSelection(Object.keys(screenTypeOptions), weights)

  const handleDiceClick = () => {
    // Randomize each property
    const newColor = randomizeSelection(Object.keys(colorOptions))
    const newScreenType = randomizeSelection(Object.keys(screenTypeOptions))
    const newScreenColor = randomizeSelection(Object.keys(screenColorOptions))
    const newFabricColor = randomizeSelection(Object.keys(fabricColorOptions))

    // Update state and send messages
    setColor(newColor)

    setScreenType(newScreenType)

    setScreenColor(newScreenColor)

    setFabricColor(newFabricColor)

    // Optionally add any animation or visual effect
    swayCamera()
  }

  const [position, setPosition] = useState([0, 0, 5]) // Example position state

  // Example function to update position
  function updatePosition() {
    new TWEEN.Tween(position)
      .to([0, 0, 2], 2000)
      .onUpdate((newPos) => setPosition(newPos))
      .start()
  }

  useEffect(() => {
    updatePosition()
  }, [])

  useEffect(() => {
    if (sceneRef.current) {
      setEnvironmentMap(sceneRef.current)
    }
  }, [sceneRef.current]) // Ensure this runs only whfen the scene is initiallfy set

  const [isOrbiting, setIsOrbiting] = useState(false)
  const updateBodySelectability = (selectable) => {
    document.body.classList.toggle('no-select', !selectable)
  }

  useEffect(() => {
    if (!orbitControlsRef.current) return

    orbitControlsRef.current.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: null // Disable right-click dragging to rotate or pan
    }

    const controls = orbitControlsRef.current
    const onStart = () => {
      setIsOrbiting(true)
      updateBodySelectability(false)
    }
    const onEnd = () => {
      setIsOrbiting(false)
      updateBodySelectability(true)
    }

    controls.addEventListener('start', onStart)
    controls.addEventListener('end', onEnd)

    return () => {
      if (controls) {
        controls.removeEventListener('start', onStart)
        controls.removeEventListener('end', onEnd)
      }
    }
  }, [orbitControlsRef.current]) // Adding orbitControlsRef.current as a dependency

  const onCanvasCreated = ({ gl, scene, camera }) => {
    sceneRef.current = scene
    cameraRef.current = camera
    // Any other initialization code...
  }

  const handleSceneCreated = () => {
    setSceneReady(true) // Set scene readiness state to true
  }

  const colorOptions = {
    '#4fb9af': 'Turquoise Teal',
    '#EA8B6A': 'Apricot',
    '#9D2515': 'Pure Red',
    '#FFFFFF': 'White',
    '#000000': 'Black',
    '#EADBBC': 'Coffee Cream',
    '#A69A8C': 'Warm Grey',
    '#D3A35B': 'Golden',
    '#FDBB26': 'Sunglow Yellow',
    '#FD8546': 'Eyelid Orange',
    '#CE6B4E': 'Copper Suede',
    '#C0979F': 'Champagne Pink',
    '#3E8E51': 'Forest Green',
    '#B5D969': 'Lime Lizard Green',
    '#81CC91': 'Mint Green',
    '#91C3DE': 'Sky Blue',
    '#F0A4AB': 'Melon Pink',
    '#746A9C': 'Orchid Purple',
    '#3E037E': 'Strange Purple',
    '#204C89': 'Azure Blue',
    '#C9998B': 'Rose Gold',
    '#120F31': 'Navy Blue',
    '#52253A': 'Black Cherry',
    '#A50456': 'Sangria',
    '#536000': 'Olive',
    '#333B00': 'Earthy Green',
    '#C3B9B0': 'Metallic'

    // Add more mappings as needed
  }

  const handleColorSelect = (selectedValue) => {
    setColor(selectedValue) // Update the selected color in your state
    const selectedName = colorOptions[selectedValue] // Get the name associated with the selected value
    console.log('Body Color selected:', selectedName) // Log the selected color for debugging
  }

  const normalizeColor = (colormatch) => {
    if (!colormatch) return color
    return colormatch.trim().toUpperCase() // Convert to uppercase and trim whitespace
  }

  const screenColorOptions = {
    '#4fb9af': 'Turquoise Teal',
    '#EA8B6A': 'Apricot',
    '#9D2515': 'Pure Red',
    '#FFFFFF': 'White',
    '#000000': 'Black',
    '#EADBBC': 'Coffee Cream',
    '#A69A8C': 'Warm Grey',
    '#D3A35B': 'Golden',
    '#FDBB26': 'Sunglow Yellow',
    '#FD8546': 'Eyelid Orange',
    '#CE6B4E': 'Copper Suede',
    '#C0979F': 'Champagne Pink',
    '#3E8E51': 'Forest Green',
    '#B5D969': 'Lime Lizard Green',
    '#81CC91': 'Mint Green',
    '#91C3DE': 'Sky Blue',
    '#F0A4AB': 'Melon Pink',
    '#746A9C': 'Orchid Purple',
    '#3E037E': 'Strange Purple',
    '#204C89': 'Azure Blue',
    '#C9998B': 'Rose Gold',
    '#120F31': 'Navy Blue',
    '#52253A': 'Black Cherry',
    '#A50456': 'Sangria',
    '#536000': 'Olive',
    '#333B00': 'Earthy Green',
    '#C3B9B0': 'Metallic'

    // Add more mappings as needed
  }

  const fabricColorOptions = {
    '#FFFFFF': 'White',
    '#000000': 'Black'
    // Add more mappings as needed
  }

  const microphoneTypeOptions = {
    Condenser: { label: 'Condenser', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Condenser-Icon.png?v=1704768823' },
    Ribbon: { label: 'Ribbon', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/Ribbon-Transducer-Icon.png?v=1704769194' }
    // Add other microphone types as necessary
  }

  const displayNames = {
    '1. Body Color': 'BODY', // Custom display name
    '2. Screen Type': 'SCREEN', // Custom display name
    '3. Screen Color': 'SCREEN COLOR', // Custom display name
    '4. Fabric Color': 'FABRIC', // Custom display name
    '5. Bottom Screen Type': 'LOGO', // Custom display name
    '6. Microphone Type': 'TYPE' // Custom display name

    // Add additional mappings here as needed
  }

  const scrollRef = useRef(null) // Ref to access the scrollable footer
  const [isDragging, setIsDragging] = useState(false)
  const [dragged, setDragged] = useState(false) // Track if a significant drag occurred
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setDragged(false) // Reset dragged state on new mouse down
    setStartX(e.pageX - scrollRef.current.offsetLeft)
    setScrollLeft(scrollRef.current.scrollLeft)
  }

  const handleOptionClick = (optionLabel) => {
    if (!dragged) {
      setIsVisible(false) // Start fade out to hide the tip
      setActiveSelection(optionLabel) // Update the active selection instantly

      setTimeout(() => {
        // Determine and set the new tip after a brief delay
        const tips = selectionTips[optionLabel] || []
        if (tips.length > 0) {
          const randomTipIndex = Math.floor(Math.random() * tips.length)
          setActiveTip(tips[randomTipIndex])
        } else {
          setActiveTip('No tips available for this selection.')
        }
        setIsVisible(true) // Fade in the new tip
      }, 300) // Match this delay with the CSS fade duration for a smooth transition
    }
    setDragged(false) // Reset after processing the click
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return

    e.preventDefault() // Prevent other actions like text selection
    const x = e.pageX - scrollRef.current.offsetLeft
    const walk = (x - startX) * 2 // Adjust this value based on your needs

    if (Math.abs(walk) > 5) {
      // You can adjust this threshold
      setDragged(true)
    }

    scrollRef.current.scrollLeft = scrollLeft - walk
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const [activeSelection, setActiveSelection] = useState('1. Body Color')

  // Cycle through options in either direction
  const cycleOptions = (options, current, direction) => {
    const keys = Object.keys(options)
    const currentIndex = keys.indexOf(current)
    const nextIndex = (currentIndex + (direction === 'next' ? 1 : -1) + keys.length) % keys.length
    return keys[nextIndex]
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        const direction = event.key === 'ArrowRight' ? 'next' : 'prev'

        switch (activeSelection) {
          case '1. Body Color':
            setColor((prev) => cycleOptions(colorOptions, prev, direction))
            break
          case '2. Screen Type':
            setScreenType((prev) => cycleOptions(screenTypeOptions, prev, direction))
            break
          case '3. Screen Color':
            setScreenColor((prev) => cycleOptions(screenColorOptions, prev, direction))
            break
          case '4. Fabric Color':
            setFabricColor((prev) => cycleOptions(fabricColorOptions, prev, direction))
            break
          case '6. Microphone Type':
            setMicrophoneType((prev) => cycleOptions(microphoneTypeOptions, prev, direction))
            break

          default:
            break
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [activeSelection, color, screenType, screenColor]) // Include all dependencies that influence the effect

  const selectionTips = {
    '1. Body Color': ['Choosing a lighter color can highlight the contours of the design.', 'Darker colors might hide fingerprints and smudges better.'],
    '2. Screen Type': ['Screens with larger openings can enhance sound clarity.', 'Tighter screens might be better for more controlled sound output.'],
    '3. Screen Color': ['Match the screen color with the body for a uniform look.', 'Contrasting colors can make the screen stand out more visually.'],
    '4. Fabric Color': ['Light fabric colors are great for vibrant setups.', 'Darker fabrics can give a more sophisticated look.'],
    '5. Bottom Screen Type': ['Adding a logo can increase brand visibility.', 'Patterned screens can add a unique touch to your design.'],
    '6. Microphone Type': [
      'Condenser microphones are excellent for capturing vocal subtleties.',
      'Dynamic microphones are better for high-volume environments.'
    ]
  }

  const tipsArray = [
    'Six month interest-free payments when you choose Shop Pay.',
    'Free shipping on orders over $150.',
    'Sign up for our newsletter and get 10% off.',
    'New customers receive a free gift with their first purchase.'
    // ... any number of tips you want to cycle through
  ]

  const [isVisible, setIsVisible] = useState(true)

  const initialTip = selectionTips[Object.keys(selectionTips)[0]][0] || 'Welcome to the tips section!' // Default tip to avoid empty content
  const [activeTip, setActiveTip] = useState(initialTip)

  useEffect(() => {
    const changeTip = () => {
      setIsVisible(false) // Start fade out

      setTimeout(() => {
        const tips = selectionTips[activeSelection] || [] // Ensure it defaults to an empty array
        if (tips.length > 0) {
          const randomTipIndex = Math.floor(Math.random() * tips.length)
          setActiveTip(tips[randomTipIndex]) // Set new tip from available tips
        } else {
          setActiveTip('No tips available for this selection.') // Default message when no tips are available
        }
        setIsVisible(true) // Start fade in
      }, 500)
    }

    const intervalId = setInterval(changeTip, 8000)
    return () => clearInterval(intervalId)
  }, [activeSelection])

  useEffect(() => {
    const selectionOrder = ['1. Body Color', '2. Screen Type', '3. Screen Color', '4. Fabric Color', '5. Bottom Screen Type', '6. Microphone Type']

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault() // Prevent the default arrow key scrolling behavior
        const currentIndex = selectionOrder.indexOf(activeSelection)
        const newIndex =
          event.key === 'ArrowDown' ? (currentIndex + 1) % selectionOrder.length : (currentIndex - 1 + selectionOrder.length) % selectionOrder.length
        setActiveSelection(selectionOrder[newIndex])
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [activeSelection]) // Dependency array to update if activeSelection changes

  const changeSelectionUp = () => {
    const index = Object.keys(selectionContent).indexOf(activeSelection)
    if (index > 0) {
      setActiveSelection(Object.keys(selectionContent)[index - 1])
    }
  }

  const changeSelectionDown = () => {
    const index = Object.keys(selectionContent).indexOf(activeSelection)
    if (index < Object.keys(selectionContent).length - 1) {
      setActiveSelection(Object.keys(selectionContent)[index + 1])
    }
  }

  const selectionRef = useRef(null)
  const scrollIndicatorRef = useRef(null)

  const navigateSelection = (direction) => {
    const selectionOrder = ['1. Body Color', '2. Screen Type', '3. Screen Color', '4. Fabric Color', '5. Bottom Screen Type', '6. Microphone Type']
    const currentIndex = selectionOrder.indexOf(activeSelection)
    let newIndex
    if (direction === 'next') {
      newIndex = (currentIndex + 1) % selectionOrder.length
    } else {
      newIndex = (currentIndex - 1 + selectionOrder.length) % selectionOrder.length
    }
    setActiveSelection(selectionOrder[newIndex])
    scrollToActiveSelection()
  }

  const scrollToActiveSelection = () => {
    // Ensure the update happens after state change renders
    setTimeout(() => {
      const activeElement = document.querySelector('.footer-option.active')
      if (activeElement && scrollRef.current) {
        scrollRef.current.scrollTo({
          left: activeElement.offsetLeft - scrollRef.current.offsetWidth / 2 + activeElement.offsetWidth / 2,
          behavior: 'smooth'
        })
      }
    }, 0)
  }

  useEffect(() => {
    scrollToActiveSelection()
  }, [activeSelection]) // This will trigger when activeSelection changes

  const handleBottomScreenTypeSelect = (value, label) => {
    setBottomScreenType(value)

    console.log('Bottom Screen Type selected:', value)
  }

  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/variables/7')
        if (!response.ok) {
          throw new Error(`Failed to fetch data: status ${response.status}`)
        }
        const data = await response.json()
      } catch (error) {
        setError(error)
      }
    }

    fetchData()
  }, [])

  const id = useID() // Get ID from context

  useEffect(() => {
    const fetchDataWithId = async () => {
      const url = new URL(window.location.href)
      const queryId = url.searchParams.get('id') // Check for ID in query parameters (e.g., ?id=12)
      const pathId = url.pathname.split('/').pop() // Check for ID in the URL path (e.g., /products/custom-mic-test/12)
      const finalId = queryId || pathId // Use query ID if available, otherwise fallback to path ID

      if (finalId) {
        console.log('Final ID:', finalId) // Log the detected ID

        try {
          const response = await fetch(`https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/variables/${finalId}`)
          if (!response.ok) throw new Error(`Failed to fetch data: status ${response.status}`)

          const data = await response.json()
          const fetchedScreenColor = data.screen_color
          const fetchedBodyColor = data.color
          const fetchedScreenType = data.screen_type
          const fetchedFabricColor = data.fabric_color
          const fetchedBottomScreenType = data.bottom_screen_type
          const fetchedMicrophoneType = data.microphone_type

          setScreenColor(fetchedScreenColor)
          setColor(fetchedBodyColor)
          setScreenType(fetchedScreenType)
          setFabricColor(fetchedFabricColor)
          setBottomScreenType(fetchedBottomScreenType)
          setMicrophoneType(fetchedMicrophoneType)
        } catch (error) {
          console.error('Error fetching data for ID:', finalId, error)
        }
      } else {
        console.log('No ID provided in URL.')
      }
    }

    fetchDataWithId()
  }, [])

  const selectionContent = {
    '1. Body Color': (
      <div className="option-content">
        <div className="header-and-selected">
          <div className="option-header">
            <h3>Body</h3>
          </div>
          <div className="selected-option">
            <div className="selected-color">{colorOptions[color]}</div>
          </div>
        </div>
        <div className="color-options">
          <div
            className="color-options-container"
            ref={colorScrollRef}
            onMouseDown={handleColorMouseDown}
            onMouseMove={handleColorMouseMove}
            onMouseUp={handleColorMouseUpOrLeave}
            onMouseLeave={handleColorMouseUpOrLeave}>
            {Object.entries(colorOptions).map(([value, name]) => (
              <div
                key={value}
                className={`color-circle ${color === value ? 'active' : ''}`}
                onClick={() => {
                  handleColorSelect(value)
                  const selectedName = colorOptions[value]

                  console.log('Body Color selected:', selectedName)
                  console.log('Body Color value:', value) // Log the selected value
                }}
                style={{ backgroundColor: value }}
                title={name}>
                {/* Optional content inside the circle */}
              </div>
            ))}
          </div>
        </div>
      </div>
    ),

    '2. Screen Type': (
      <div>
        <div className="option-content">
          <div className="header-and-selected">
            <div className="option-header">
              <h3>Screen</h3>
            </div>
            <div className="selected-option">
              <div className="selected-color">{screenTypeOptions[screenType].label}</div>
            </div>
          </div>

          <div className="color-options">
            <div className="screen-options-container">
              {Object.entries(screenTypeOptions).map(([value, { label, iconUrl }]) => (
                <div
                  key={value}
                  className="screen-type-option"
                  onClick={() => {
                    setScreenType(value)
                    const screenTypeLabel = label

                    console.log('Screen Type selected:', screenTypeLabel)
                    console.log('Screen Type value:', value)
                  }}>
                  <div className="icon-container">
                    <img src={iconUrl} alt={label} className={`screen-type-icon ${screenType === value ? 'active' : ''}`} />
                    {screenType === value && (
                      <svg
                        className="highlight-circle"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#f16a30"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                      </svg>
                    )}
                  </div>
                  <span className="screen-type-label"></span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ),
    '3. Screen Color': (
      <div>
        <div className="option-content"></div>

        <div className="header-and-selected">
          <div className="option-header">
            <h3>Screen</h3>
          </div>
          <div className="selected-option">
            <div className="selected-color">{screenColorOptions[screenColor]}</div>
          </div>
        </div>
        <div className="color-options">
          <div
            className="color-options-container"
            ref={screenColorScrollRef}
            onMouseDown={handleScreenColorMouseDown}
            onMouseMove={handleScreenColorMouseMove}
            onMouseLeave={handleScreenColorMouseUpOrLeave}
            onMouseUp={handleScreenColorMouseUpOrLeave}>
            {Object.entries(screenColorOptions).map(([value, name]) => (
              <div
                key={value}
                className={`color-circle ${screenColor === value ? 'active' : ''}`}
                onClick={() => {
                  setScreenColor(value)
                  const selectedName = screenColorOptions[value]
                  const selectedValue = value // Define selectedValue

                  console.log('Screen Color selected:', selectedName)
                  console.log('Screen Color value:', selectedValue) // Log selectedValue
                }}
                style={{ backgroundColor: value }}
                title={name}>
                {/* Optional content inside the circle */}
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    '4. Fabric Color': (
      <div>
        <div className="option-content"></div>

        <div className="header-and-selected">
          <div className="option-header">
            <h3>Fabric</h3>
          </div>
          <div className="selected-option">
            <div className="selected-color">{fabricColorOptions[fabricColor]}</div>
          </div>
        </div>

        <div className="color-options">
          <div className="color-options-container">
            {Object.entries(fabricColorOptions).map(([value, name]) => (
              <div
                key={value}
                className={`color-circle ${fabricColor === value ? 'active' : ''}`}
                onClick={() => {
                  setFabricColor(value)
                  const selectedName = fabricColorOptions[value]
                  const selectedValue = value // Define selectedValue

                  console.log('Fabric Color selected:', selectedName)
                  console.log('Fabric Color value:', selectedValue) // Log selectedValue
                }}
                style={{
                  backgroundColor: value,
                  border: value === '#FFFFFF' ? '2px solid black' : 'none' // Adds black border only for white color
                }}
                title={name}>
                {/* Optional content inside the circle */}
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    // ... Add content for options 5 to 10 here
    '5. Bottom Screen Type': (
      <div className="option-content">
        <div className="header-and-selected">
          <div className="option-header">
            <h3>Logo</h3>
          </div>{' '}
          <div className="selected-option">
            <div className="selected-color">{bottomScreenTypeOptions[bottomScreenType].label}</div>
          </div>
        </div>
        <div className="bottom-screen-options-container">
          {Object.entries(bottomScreenTypeOptions).map(([value, { label, iconUrl }]) => (
            <div
              key={value}
              className={`bottom-screen-type-option ${bottomScreenType === value ? 'active' : ''}`}
              onClick={() => handleBottomScreenTypeSelect(value, label)}>
              <div className="icon-container">
                <img src={iconUrl} alt={label} className="bottom-screen-type-icon" />
                {bottomScreenType === value && (
                  <svg
                    className="highlight-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#f16a30"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"></svg>
                )}
              </div>
              <span className="bottom-screen-type-label"></span>
            </div>
          ))}
        </div>
      </div>
    ),

    // ...

    '6. Microphone Type': (
      <div>
        <div className="option-content">
          <div className="header-and-selected">
            <div className="option-header">
              <h3>Mic Type</h3>
            </div>
            <div className="selected-option">
              <div className="selected-color">{microphoneTypeOptions[microphoneType].label}</div>
            </div>
          </div>
          <div className="microphone-options-container">
            {Object.entries(microphoneTypeOptions).map(([value, { label, iconUrl }]) => (
              <div
                key={value}
                className={`microphone-type-option ${microphoneType === value ? 'active' : ''}`}
                onClick={() => {
                  setMicrophoneType(value)
                  const microphoneTypeLabel = label

                  console.log('Microphone Type selected:', microphoneTypeLabel)
                  console.log('Microphone Type value:', value)
                }}>
                <div className="icon-container">
                  <img src={iconUrl} alt={label} className="microphone-type-icon" style={{ width: '45px', height: '45px' }} />
                  {microphoneType === value && (
                    <svg
                      className="highlight-circle"
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#f16a30"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <circle cx="12" cy="12" r="10"></circle>
                    </svg>
                  )}
                </div>
                <span className="microphone-type-label"></span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const handleSelectionClick = (optionLabel) => {
    setActiveSelection(optionLabel)
  }

  AWS.config.update({
    accessKeyId: 'AKIAQ3EGPYYCUGGVRPFF',
    secretAccessKey: 'seX4Ppgbb39rfDOfBt0S5a5dCQehNZTSJ8gO5lSn',
    region: 'us-west-1' // e.g., 'us-west-2'
  })

  const s3 = new AWS.S3()

  // Define initial camera position and orientation
  const INITIAL_CAMERA_POSITION = [0, 0, 2]
  const INITIAL_CAMERA_ROTATION = [0, 0, 0] // Assuming no initial rotation

  const SIDE_CAMERA_POSITION = [
    INITIAL_CAMERA_POSITION[2] * Math.cos(Math.PI / 4), // x-coordinate
    0, // y-coordinate remains the same
    INITIAL_CAMERA_POSITION[2] * Math.sin(Math.PI / 4) // z-coordinate
  ]

  const BACK_CAMERA_POSITION = [0, 0, -2]

  // Inside your App component
  const [initialCameraPosition] = useState(INITIAL_CAMERA_POSITION)
  const [initialCameraRotation] = useState(INITIAL_CAMERA_ROTATION)

  const resetCamera = () => {
    if (cameraRef.current && orbitControlsRef.current) {
      const initialPosition = cameraRef.current.position.clone()
      const targetPosition = new THREE.Vector3(...INITIAL_CAMERA_POSITION)

      new TWEEN.Tween(initialPosition)
        .to(targetPosition, 1000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate(() => {
          cameraRef.current.position.copy(initialPosition)
          cameraRef.current.lookAt(0, 0, 0) // Center of the scene
        })
        .onComplete(() => {
          orbitControlsRef.current.target.set(0, 0, 0)
          orbitControlsRef.current.update()
        })
        .start()
    }
  }

  const rotateCameraBehind = () => {
    if (cameraRef.current && orbitControlsRef.current) {
      const initialPosition = cameraRef.current.position.clone()
      const targetPosition = new THREE.Vector3(...BACK_CAMERA_POSITION) // Assuming you have defined BACK_CAMERA_POSITION

      const initialRotation = cameraRef.current.rotation.clone()
      const targetRotation = new THREE.Euler(0, -Math.PI / 2, 0) // Rotate 90 degrees to the opposite direction

      new TWEEN.Tween({ x: initialPosition.x, y: initialPosition.y, z: initialPosition.z, rotationY: initialRotation.y })
        .to({ x: targetPosition.x, y: targetPosition.y, z: targetPosition.z, rotationY: targetRotation.y }, 2000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate(({ x, y, z, rotationY }) => {
          cameraRef.current.position.set(x, y, z)
          cameraRef.current.rotation.set(initialRotation.x, rotationY, initialRotation.z)
          cameraRef.current.lookAt(0, 0, 0) // Center of the scene
        })
        .onComplete(() => {
          orbitControlsRef.current.target.set(0, 0, 0)
          orbitControlsRef.current.update()
        })
        .start()
    }
  }

  const resetCameraSide = () => {
    if (cameraRef.current && orbitControlsRef.current) {
      const initialPosition = cameraRef.current.position.clone()
      const targetPosition = new THREE.Vector3(...SIDE_CAMERA_POSITION) // Assuming you have defined SIDE_CAMERA_POSITION

      const initialRotation = cameraRef.current.rotation.clone()
      const targetRotation = new THREE.Euler(0, -Math.PI / 2, 0) // Rotate 90 degrees to the opposite direction

      new TWEEN.Tween({ x: initialPosition.x, y: initialPosition.y, z: initialPosition.z, rotationY: initialRotation.y })
        .to({ x: targetPosition.x, y: targetPosition.y, z: targetPosition.z, rotationY: targetRotation.y }, 2000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate(({ x, y, z, rotationY }) => {
          cameraRef.current.position.set(x, y, z)
          cameraRef.current.rotation.set(initialRotation.x, rotationY, initialRotation.z)
          cameraRef.current.lookAt(0, 0, 0) // Center of the scene
        })
        .onComplete(() => {
          orbitControlsRef.current.target.set(0, 0, 0)
          orbitControlsRef.current.update()
        })
        .start()
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      resetCameraSide()
      setActiveDot('dots-side') // Set the active dot to "dots-side"
    }, 1500) // 5000 ms = 5 seconds

    // Cleanup the timer if the component unmounts before the 5 seconds
    return () => clearTimeout(timer)
  }, [])

  const openCartDrawer = () => {
    document.dispatchEvent(new CustomEvent('theme:open-cart-drawer', { bubbles: true, cancelable: false }))
  }

  const addToCart = async () => {
    let awsThumbnailUrl = '' // Declare awsThumbnailUrl in the broader scope of addToCart
    const placeholderImageUrl = 'https://ohmaworld.com/cdn/shop/products/ZAp4bjlO_91171723-896c-43c7-b23e-31a06fdd4f9d.png' // Placeholder image URL

    if (!sceneRef.current || !cameraRef.current) {
      console.error('Scene or camera is not defined.')
      return
    }

    const saatiMesh = sceneRef.current.getObjectByName('saati_top')
    const topScreenMesh = sceneRef.current.getObjectByName(`${screenType}_top`)

    if (saatiMesh) {
      saatiMesh.visible = true
      saatiMesh.material.opacity = 1
      saatiMesh.material.transparent = true
    }

    if (topScreenMesh) {
      topScreenMesh.visible = true
      topScreenMesh.material.opacity = 1
      topScreenMesh.material.transparent = true
    }

    // Create and use the thumbnail camera
    const thumbnailCamera = createThumbnailCamera(cameraRef.current)

    sceneRef.current.traverse((object) => {
      if ((object instanceof THREE.DirectionalLight || object instanceof THREE.SpotLight) && !object.castShadow) {
        object.castShadow = true
        // Optionally, adjust shadow map settings for performance
        object.shadow.mapSize.width = 512 // Default is 512
        object.shadow.mapSize.height = 512 // Default is 512
      }
      if (object.isMesh) {
        object.receiveShadow = true
      }
    })

    setEnvironmentMap(sceneRef.current)
    // Make sure your renderer is configured to handle shadows

    // Create a high-resolution offscreen canvas
    const scale = 1 // Supersampling factor for higher resolution rendering
    const highResWidth = 180 * scale
    const highResHeight = 180 * scale
    const offscreenCanvas = document.createElement('canvas')
    offscreenCanvas.width = highResWidth
    offscreenCanvas.height = highResHeight

    // Set the thumbnail camera to the initial position and rotation
    thumbnailCamera.position.set(...INITIAL_CAMERA_POSITION)
    thumbnailCamera.lookAt(sceneRef.current.position) // Assuming you want to look at the center of the scene
    thumbnailCamera.updateProjectionMatrix()

    // The rest of your rt logic follows here...

    // Use thumbnailCamera for rendering without re-declaring it
    thumbnailCamera.aspect = highResWidth / highResHeight
    thumbnailCamera.updateProjectionMatrix()

    // Use the WebGLRenderer with the offscreen canvas
    const renderer = new THREE.WebGLRenderer({ canvas: offscreenCanvas, antialias: true })
    renderer.setSize(highResWidth, highResHeight)
    renderer.setClearColor(0x000000, 0) // Optional: Transparent background

    // Adjust the camera for the high-resolution rendering

    thumbnailCamera.aspect = highResWidth / highResHeight
    thumbnailCamera.updateProjectionMatrix()

    const closerZ = thumbnailCamera.position.z - 0.35
    thumbnailCamera.position.z = closerZ

    const lowerY = thumbnailCamera.position.y + 0.1 // Adjust this value as needed
    thumbnailCamera.position.y = lowerY

    const backdropGeometry = new THREE.PlaneGeometry(100, 100) // Adjust size as needed
    const backdropMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff' }) // Blue color for the backdrop
    const backdropMesh = new THREE.Mesh(backdropGeometry, backdropMaterial)
    backdropMesh.position.z = -5 // Set the position behind the model, adjust as needed
    sceneRef.current.add(backdropMesh)

    const groundGeometry = new THREE.PlaneGeometry(100, 100) // Large enough to act as a ground
    const groundMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff' }) // Blue color
    const groundMesh = new THREE.Mesh(groundGeometry, groundMaterial)
    groundMesh.rotation.x = -Math.PI / 2 // Rotate the plane to be horizontal
    groundMesh.position.y = -0.499 // Adjust based on the bottom of your model
    sceneRef.current.add(groundMesh)

    // Render the scene with the high-resolution camera
    renderer.render(sceneRef.current, thumbnailCamera)
    sceneRef.current.remove(groundMesh)
    sceneRef.current.remove(backdropMesh)

    // Create another canvas to perform the image resizing
    const resizeCanvas = document.createElement('canvas')
    resizeCanvas.width = 180 // Target dimensions
    resizeCanvas.height = 180
    const ctx = resizeCanvas.getContext('2d')

    // Draw the high-resolution image onto the resizing canvas, effectively resizing it
    ctx.drawImage(offscreenCanvas, 0, 0, resizeCanvas.width, resizeCanvas.height)

    // Scene setup and rendering logic here
    resizeCanvas.toBlob(async (blob) => {
      const fileid = Date.now() // Get the timestamp
      const fileKey = `${fileid}_microphone_thumbnail.png`

      const params = {
        Bucket: 'ohmacustomizerphotos',
        Key: fileKey,
        Body: blob,
        ContentType: 'image/png',
        ACL: 'public-read'
      }

      try {
        // Step 1: Upload to S3
        const s3UploadResult = await new Promise((resolve, reject) => {
          s3.upload(params, (err, data) => {
            if (err) reject(err)
            else resolve(data.Location)
          })
        })
        awsThumbnailUrl = s3UploadResult
        console.log('Thumbnail uploaded to S3:', awsThumbnailUrl)

        // Step 2: Send data to Heroku
        const herokuResponse = await fetch('https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/receive-data', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            color: color,
            screenType: screenType,
            screenColor: screenColor,
            fabricColor: fabricColor,
            bottomScreenType: bottomScreenType,
            microphoneType: microphoneType,
            Pic: awsThumbnailUrl,
            fileid: fileid // Add fileId to Heroku payload
          })
        })

        if (!herokuResponse.ok) throw new Error('Failed to send data to Heroku')
        const herokuData = await herokuResponse.json()
        console.log('Data sent successfully to Heroku:', herokuData)

        // Extract the `id` from the response
        const id = herokuData.data?.id
        if (!id) {
          throw new Error('No ID returned from Heroku.')
        }
        console.log('Data successfully sent to Heroku. Shareable ID:', fileid)
        const shareableLink = `https://ohmaworld.com/products/custom-mic-test/${fileid}`
        console.log('Shareable link:', shareableLink)

        // Update the browser's URL to include the ID
        window.history.pushState({}, '', shareableLink)

        // Step 3: Send data to Shopify
        const shopifyProperties = {
          Color: colorOptions[color],
          'Screen Type': screenTypeOptions[screenType]?.label,
          'Screen Color': screenColorOptions[screenColor],
          'Fabric Color': fabricColorOptions[fabricColor],
          'Bottom Screen Type': bottomScreenType,
          'Microphone Type': microphoneType,
          Pic: awsThumbnailUrl,
          fileid: fileid // Add fileId to Heroku payload
        }

        const shopifyResponse = await fetch('/cart/add.js', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            items: [
              {
                id: '43167140184164', //variant id for shopify page
                quantity: 1,
                properties: shopifyProperties
              }
            ]
          })
        })

        if (!shopifyResponse.ok) throw new Error('Failed to add item to Shopify cart')
        const shopifyData = await shopifyResponse.json()
        console.log('Item successfully added to Shopify cart:', shopifyData)

        document.dispatchEvent(new CustomEvent('theme:cartchanged', { bubbles: true, cancelable: false }))

        // Add a slight delay before refreshing the cart content
        setTimeout(() => {
          openCartDrawer() // Open the drawer right away
        }, 500) // Adjust delay if necessary
      } catch (error) {
        console.error('Error occurred:', error)
      }
    }, 'image/png')
  }

  // Define the function to update the cart item with the custom image
  function updateCartItemWithCustomImage(imageUrl) {
    setTimeout(() => {
      const cartItem = document.querySelector('.cart-item') // Adjust if needed
      if (cartItem) {
        const existingImage = cartItem.querySelector('.cart-item__image img')
        console.log('Cart item found:', cartItem) // Debug log
        if (existingImage) {
          console.log('Existing image element found:', existingImage) // Debug log
          existingImage.src = imageUrl
        } else {
          console.log('No existing image found. Creating new image element.')
          const newImage = document.createElement('img')
          newImage.src = imageUrl
          newImage.alt = 'Custom Image'
          const imageContainer = cartItem.querySelector('.cart-item__image')
          if (imageContainer) {
            imageContainer.appendChild(newImage)
          } else {
            console.log('Image container not found.')
          }
        }
      } else {
        console.log('Cart item not found.')
      }
    }, 500) // Adjust timeout as needed
  }

  const thumby = 'https://ohmacustomizerphotos.s3.us-west-1.amazonaws.com/1730936270086_shared_thumbnail.png'
  const shareProduct = async () => {
    try {
      // Generate and upload the thumbnail
      const thumbnailUrl = await generateThumbnail()
      if (!thumbnailUrl) {
        throw new Error('Failed to generate thumbnail')
      }
      // Gather all current options
      const dataToSend = {
        color: color,
        screenType: screenType,
        screenColor: screenColor,
        fabricColor: fabricColor,
        bottomScreenType: bottomScreenType,
        microphoneType: microphoneType,
        sku: thumby
      }

      console.log('Data being sent to Heroku for sharing:', dataToSend)

      // Send data to Heroku
      const herokuResponse = await fetch('https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/receive-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      })

      if (!herokuResponse.ok) {
        const errorText = await herokuResponse.text()
        throw new Error(`Failed to send data to Heroku: ${errorText}`)
      }

      // Log the full response for debugging
      const responseData = await herokuResponse.json()
      console.log('Full Heroku response:', responseData)

      // Extract the shareable ID from the nested `data` object
      const id = responseData.data?.id
      if (!id) {
        throw new Error('No ID returned from Heroku.')
      }
      console.log('Data successfully sent to Heroku. Shareable ID:', id)

      // Construct the shareable link
      const shareableLink = `https://ohmaworld.com/products/custom-mic-test/${fileid}`
      console.log('Shareable link:', shareableLink)

      // Update the browser's URL to include the ID
      window.history.pushState({}, '', shareableLink) // Updates the URL to the shareable link without reloading the page

      // Use the Web Share API
      if (navigator.share) {
        await navigator.share({
          title: 'Check out this custom microphone!',
          url: shareableLink
        })
        console.log('Link shared successfully!')
      } else {
        console.warn('Web Share API not supported on this browser.')
        // Fallback: Copy to clipboard or display the link
        navigator.clipboard.writeText(shareableLink)
        alert('Link copied to clipboard: ' + shareableLink)
      }
    } catch (error) {
      console.error('Error sharing product:', error.message)
    }
  }

  const generateThumbnail = async () => {
    if (!sceneRef.current || !cameraRef.current) {
      console.error('Scene or camera is not defined.')
      return null
    }

    // Ensure the model is visible
    const saatiMesh = sceneRef.current.getObjectByName('saati_top')
    const topScreenMesh = sceneRef.current.getObjectByName(`${screenType}_top`)

    if (saatiMesh) {
      saatiMesh.visible = true
      saatiMesh.material.opacity = 1
      saatiMesh.material.transparent = true
    } else {
      console.warn('Saati mesh not found.')
    }

    if (topScreenMesh) {
      topScreenMesh.visible = true
      topScreenMesh.material.opacity = 1
      topScreenMesh.material.transparent = true
    } else {
      console.warn('Top screen mesh not found.')
    }

    // Create and configure the thumbnail camera
    const thumbnailCamera = createThumbnailCamera(cameraRef.current)
    thumbnailCamera.aspect = 1 // Square thumbnail
    thumbnailCamera.updateProjectionMatrix()

    // Reset camera position and rotation to the initial values
    thumbnailCamera.position.set(...INITIAL_CAMERA_POSITION) // Replace with your actual initial position
    thumbnailCamera.rotation.set(...INITIAL_CAMERA_ROTATION) // Replace with your actual initial rotation
    thumbnailCamera.lookAt(sceneRef.current.position) // Ensure the camera is looking at the model
    thumbnailCamera.updateProjectionMatrix()

    const offscreenCanvas = document.createElement('canvas')
    offscreenCanvas.width = 180
    offscreenCanvas.height = 180

    const renderer = new THREE.WebGLRenderer({
      canvas: offscreenCanvas,
      antialias: true
    })
    renderer.setSize(180, 180)
    renderer.setClearColor(0xffffff, 1) // Ensure white background

    // Optional: Add a backdrop for better visibility
    const backdropGeometry = new THREE.PlaneGeometry(100, 100)
    const backdropMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff' })
    const backdropMesh = new THREE.Mesh(backdropGeometry, backdropMaterial)
    backdropMesh.position.z = -5
    sceneRef.current.add(backdropMesh)

    // Render the scene
    renderer.render(sceneRef.current, thumbnailCamera)

    // Remove backdrop after rendering
    sceneRef.current.remove(backdropMesh)

    // Resize and convert the canvas to a Blob
    const resizeCanvas = document.createElement('canvas')
    resizeCanvas.width = 180
    resizeCanvas.height = 180
    const ctx = resizeCanvas.getContext('2d')
    ctx.drawImage(offscreenCanvas, 0, 0, resizeCanvas.width, resizeCanvas.height)

    const blob = await new Promise((resolve) => resizeCanvas.toBlob(resolve, 'image/png'))

    // Upload the Blob to S3
    const fileid = Date.now() // Get the timestamp
    const fileKey = `${fileid}_shared_thumbnail.png` // Use the same timestamp for fileKey

    const params = {
      Bucket: 'ohmacustomizerphotos',
      Key: fileKey,
      Body: blob,
      ContentType: 'image/png',
      ACL: 'public-read'
    }

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading to S3:', err)
          reject(err)
        } else {
          console.log('Thumbnail uploaded successfully to S3:', data.Location)
          // Call setShareImage with the static URL after the thumbnail generation
          setShareImage('https://ohmacustomizerphotos.s3.us-west-1.amazonaws.com/1730936270086_shared_thumbnail.png')

          resolve(data.Location)
        }
      })
    })
  }

  const setShareImage = (url) => {
    try {
      const ogImageTag = document.querySelector('meta[property="og:image"]')
      const ogImageSecureTag = document.querySelector('meta[property="og:image:secure_url"]')
      if (ogImageTag && ogImageSecureTag) {
        ogImageTag.setAttribute('content', url)
        ogImageSecureTag.setAttribute('content', url)
        console.log('Updated share image to:', url)
      } else {
        console.warn('OG image tags not found in the document.')
      }
    } catch (error) {
      console.error('Error updating share image:', error)
    }
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const takeScreenshot = () => {
    const canvas = canvasRef.current
    if (canvas) {
      const url = canvas.toDataURL('image/png')
      const link = document.createElement('a')
      link.href = url
      link.download = 'OhmaWorldMicrophone.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  function ScreenshotControl({ canvasRef }) {
    const takeScreenshot = () => {
      const canvas = canvasRef.current
      const url = canvas.toDataURL('image/png')
      const link = document.createElement('a')
      link.href = url
      link.download = 'OhmaWorldMicrophone.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    return <div style={{ position: 'absolute', zIndex: 1, top: '20px', right: '20px' }}></div>
  }

  // Inside your component
  useEffect(() => {
    // Prepare the initial states for each selection to Shopify, except "Body Color"
    const selections = [
      { property: 'Bottom Screen Type', value: capitalizeFirstLetter(bottomScreenType) },
      { property: 'Microphone Type', value: capitalizeFirstLetter(microphoneType) }
    ]

    const bodyColorName = colorOptions[color] // Retrieve the display name for "Body Color
    const screenColorName = screenColorOptions[screenColor] // Directly retrieve the display name
    const fabricColorName = fabricColorOptions[fabricColor] // Retrieve the display name for "Fabric Color"
    const screenTypeName = screenTypeOptions[screenType].label // Fetch the label using the key

    // Then, send the initial states for other selections
    selections.forEach((selection) => {})
  }, []) // The empty dependency array ensures this effect runs once on mount.

  const sendColorToShopify = (selectedColor) => {
    console.log(`Body Color selected: ${selectedColor}`)
  }

  // Repeat a similar structure for sending other types of selections
  const sendScreenTypeToShopify = useMemo(
    () =>
      debounce((selectedScreenType) => {
        console.log(`Screen Type selected: ${selectedScreenType}`)
      }, 500),
    []
  )

  const sendColorDebounced = useMemo(() => debounce(sendColorToShopify, 500), [])

  const sendCurrentStateToParent = () => {
    const currentState = {
      color,
      screenType,
      screenColor,
      fabricColor,
      bottomScreenType,
      microphoneType
    }
  }

  const sendStateDebounced = useMemo(
    () => debounce(sendCurrentStateToParent, 500),
    [sendCurrentStateToParent, color, screenType, screenColor, fabricColor, bottomScreenType, microphoneType]
  )

  const handleSelectionChange = (newSelection) => {
    setActiveSelection(newSelection)
    const tips = selectionTips[newSelection]
    setActiveTip(tips[0]) // Immediately show the first tip from the new selection
  }

  return (
    <>
      {sceneReady && <ScreenshotControl canvasRef={canvasRef} />}

      <div className="maincontainer">
        <div className="modelcontainer">
          <div className="app-container">
            {!id && !sceneReady && <LoadingIndicator />}
            <Canvas
              shadows={true}
              gl={{
                preserveDrawingBuffer: true,
                gammaOutput: true,
                gammaFactor: 1,
                toneMapping: THREE.ACESFilmicToneMapping,
                toneMappingExposure: 1
              }}
              ref={canvasRef}
              onCreated={({ gl, scene, camera }) => {
                sceneRef.current = scene
                cameraRef.current = camera

                handleSceneCreated() // Call your existing function
                setSceneReady(true) // Indicate the scene is ready
                // Any additional setup can go here
              }}
              shadows
              camera={{ position: [0, 0, 2], fov: 35 }}
              style={{
                backgroundColor: '#91c3de',
                position: 'relative',
                bottom: 10,
                left: 0,
                width: '100vw',
                height: '65vh',
                zIndex: 0
              }}>
              <ambientLight intensity={0} />
              <spotLight position={[10, 10, 10]} angle={0.3} penumbra={0.2} intensity={2} castShadow />

              <directionalLight position={[-8, 12, 8]} intensity={10} castShadow shadow-bias={-0.0001} />

              <group position={[-0, -0.3, 0]}>
                <Center top>
                  <Suzi
                    ref={modelRef}
                    rotation={[0, 0, 0]}
                    scale={1.9}
                    color={color}
                    screenType={screenType}
                    screenColor={screenColor}
                    fabricColor={fabricColor}
                    bottomScreenType={bottomScreenType}
                    microphoneType={microphoneType}
                    activeSelection={activeSelection}
                  />
                </Center>

                <LightMarker position={lightPosition} />
              </group>
              <OrbitControls
                ref={orbitControlsRef}
                minPolarAngle={0}
                enablePan={false}
                maxPolarAngle={Math.PI / 2}
                enableDamping
                dampingFactor={0.05}
                maxDistance={2}
                minDistance={2}
              />

              <AnimationUpdater />
            </Canvas>
          </div>
        </div>
        <div className="bottomhalfcontainer">
          <div className="footer-div">
            <div className="left-column">
              <div className="rectangles-container">
                <div className="dots-more">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/241101_Download.png?v=1730478482"
                    width="30"
                    height="30"
                    onClick={takeScreenshot}
                    alt="Download Screenshot"
                    style={{ cursor: 'pointer' }} // Optional: add pointer cursor for better UXf
                  />
                </div>

                <div className="share">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/241101_Share.png?v=1730477928"
                    width="30"
                    height="30"
                    onClick={shareProduct}
                    alt="Share"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>

            <div class="dots-container">
              <div class="control-dots">
                <div class="dots active" onClick={() => setActiveDot('dots', resetCamera)}></div>
                <div class="dots-side" onClick={() => setActiveDot('dots-side', resetCameraSide)}></div>
                <div class="dots-back" onClick={() => setActiveDot('dots-back', rotateCameraBehind)}></div>
                <div class="dots-crazy" onClick={() => setActiveDot('dots-crazy', rotateCameraBehind)}></div>
              </div>
            </div>

            <div className="dice-icon">
              <img src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/241101_Randomize.png?v=1730478482" alt="Dice Icon" onClick={handleDiceClick} />
            </div>

            <div
              className="scrollable-footer"
              ref={scrollRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseUp}
              onMouseUp={handleMouseUp}
              onKeyDown={(event) => {
                if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                  event.preventDefault() // Prevent scrolling
                  event.stopPropagation() // Stop the event from propagating further
                }
              }}>
              {Object.keys(selectionContent).map((optionLabel) => (
                <div
                  key={optionLabel}
                  className={`footer-option ${activeSelection === optionLabel ? 'active' : ''}`}
                  onClick={() => handleOptionClick(optionLabel)}>
                  {displayNames[optionLabel] || optionLabel} {/* Use custom name or fallback */}
                </div>
              ))}
            </div>

            <div className="selection" ref={selectionRef}>
              {selectionContent[activeSelection] || <div>Select an option</div>}
            </div>

            <div className="add-to-cart" onClick={addToCart}>
              <h1>Add to Cart</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
